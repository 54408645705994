// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import prepareUrl from './prepareUrl';
import api from './api';

// see common/php/plib/Db/Table/Row/LongTask.php
export const STATUS_NOT_STARTED = 'not_started';
export const STATUS_STARTED = 'started';
export const STATUS_DONE = 'done';
export const STATUS_CANCELED = 'canceled';
export const STATUS_ERROR = 'error';

export class StatusMessage extends Component {
    _failedStatusUpdates = 0;

    _maxFailedStatusUpdates = 10;

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._id = this._getConfigParam('id');
        this._onStatusUpdate = this._getConfigParam('onStatusUpdate', null);
        this._onTaskDone = this._getConfigParam('onDone', null);
        this._onTaskCancel = this._getConfigParam('onCancel', null);
        this._onTaskError = this._getConfigParam('onError', null);
        this._renderMessage = this._getConfigParam('renderMessage', status => {
            if (!status.message) {
                return 'Internal error.';
            }
            if (status.progressParams.errorMessage) {
                const messages = status.progressParams.errorMessage.split('\n');
                if (messages.length <= 1) {
                    return status.progressParams.errorMessage;
                }
                let message = `${messages[0]} <a href="#" class="js-details-link">${this.lmsg('details')}</a><br>`;
                message += '<span class="js-details hidden">';
                for (let i = 1; i < messages.length; i++) {
                    message += `${messages[i]}<br>`;
                }
                message += '</span>';
                return message;
            }
            return status.message;
        });

        this._statusUrl = this._getConfigParam('statusUrl', prepareUrl('/task/status/taskId/'));
        this._updateInterval = this._getConfigParam('updateInterval', 60);
        this._autoRender = true;
    }

    _showProgress(status) {
        let statusClass = 'msg-progress';
        if (STATUS_DONE === status.status) {
            if (status.progressParams.errorMessage) {
                statusClass = 'msg-warning';
            } else {
                statusClass = 'msg-info';
            }
        } else if (STATUS_CANCELED === status.status) {
            statusClass = 'msg-warning';
        } else if (STATUS_ERROR === status.status) {
            statusClass = 'msg-error';
        }

        let message = this._renderMessage(status);

        if (STATUS_DONE !== status.status && status.progress) {
            if (this._getConfigParam('percent', true)) {
                message = `${message}: <span id="backupProgress">${status.progress}</span>%`;
            }

            if (this._getConfigParam('gauge', true)) {
                message = (
                    '<table class="msg-progress-container" cellspacing="0" width="100%"><tr>' +
                        `<td class="msg-progress-info">${message}</td>` +
                        '<td class="msg-progress-indicator">' +
                            '<div class="progress progress-sm">' +
                                `<div class="progress-bar" id="backupProgressGauge" style="width: ${status.progress}%;"></div>` +
                            '</div>' +
                        '</td>' +
                    '</tr></table>'
                );
            }
        }

        this._componentElement.className = `msg-box ${statusClass}`;
        this._componentElement.innerHTML = `<div class="msg-content">${message}</div>`;
    }

    _addEvents() {
        super._addEvents();
        this._processStatus(this._getConfigParam('status', null));
    }

    _scheduleUpdateStatus() {
        setTimeout(() => {
            this._updateStatus();
        }, this._updateInterval * 1000);
    }

    _updateStatus() {
        api.get(this._statusUrl + this._id)
            .then(this._onStatusUpdateSuccess.bind(this))
            .catch(this._onStatusUpdateFailure.bind(this));
    }

    _onStatusUpdateSuccess(status) {
        this._failedStatusUpdates = 0;
        this._processStatus(status);
        if (this._onStatusUpdate) {
            this._onStatusUpdate(status);
        }
    }

    _onStatusUpdateFailure() {
        this._failedStatusUpdates++;
        if (this._failedStatusUpdates < this._maxFailedStatusUpdates) {
            this._scheduleUpdateStatus();
        }
    }

    _processStatus(status) {
        if (!status) {
            this._showLoading();
            this._updateStatus();
        } else if (STATUS_DONE === status.status) {
            if (this._onTaskDone) {
                this._onTaskDone(status);
            }
            this._showFinished(status);
        } else if (STATUS_CANCELED === status.status) {
            if (this._onTaskCancel) {
                this._onTaskCancel(status);
            }
            this._showFinished(status);
        } else if (STATUS_ERROR === status.status) {
            if (this._onTaskError) {
                this._onTaskError(status);
            }
            this._showError(status);
        } else if (STATUS_STARTED === status.status || STATUS_NOT_STARTED === status.status) {
            this._showWorking(status);
            this._scheduleUpdateStatus();
        } else {
            this._showNone(status);
        }
    }

    _addDetailsEvent() {
        const details = this._componentElement.querySelector('.js-details-link');
        if (!details) {
            return;
        }
        details.addEventListener('click', event => {
            event.preventDefault();
            this._componentElement.querySelector('.js-details').classList.toggle('hidden');
            details.classList.toggle('hidden');
        });
    }

    _showFinished(status) {
        this._showProgress(status);
        setTimeout(() => {
            this._addDetailsEvent();
        }, 0);
    }

    _showWorking(status) {
        this._showProgress(status);
    }

    _showError(status) {
        this._showProgress(status);
        setTimeout(() => {
            this._addDetailsEvent();
        }, 0);
    }

    _showLoading() {
        // empty implementation
    }

    _showNone() {
        // empty implementation
    }
}
