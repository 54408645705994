// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

const entries = {
    'app/editlist': () => import(/* webpackChunkName: "app/editlist" */'../app/editlist'),
    'app/rating': () => import(/* webpackChunkName: "app/rating" */'../app/rating'),
    'app/aha': () => import(/* webpackChunkName: "app/aha" */'../app/aha'),
    'app/eula': () => import(/* webpackChunkName: "app/eula" */'../app/eula'),
    'app/app-image/gallery': () => import(/* webpackChunkName: "app/app-image/gallery" */'../app/app-image/gallery'),
    'app/asp-dot-net/conn_strings-dynamic': () => import(/* webpackChunkName: "app/asp-dot-net/conn_strings-dynamic" */'../app/asp-dot-net/conn_strings-dynamic'),
    'app/asp-dot-net/custom_errors-dynamic': () => import(/* webpackChunkName: "app/asp-dot-net/custom_errors-dynamic" */'../app/asp-dot-net/custom_errors-dynamic'),
    'app/backup/list': () => import(/* webpackChunkName: "app/backup/list" */'../app/backup/list'),
    'app/backup/restore': () => import(/* webpackChunkName: "app/backup/restore" */'../app/backup/restore'),
    'app/backup/upload-files': () => import(/* webpackChunkName: "app/backup/upload-files" */'../app/backup/upload-files'),
    'app/backup/web-content-dynamic': () => import(/* webpackChunkName: "app/backup/web-content-dynamic" */'../app/backup/web-content-dynamic'),
    'app/cloud-backup-restriction': () => import(/* webpackChunkName: "app/cloud-backup-restriction" */'../app/cloud-backup-restriction'),
    'app/database/assign-to-subscription': () => import(/* webpackChunkName: "app/database/assign-to-subscription" */'./views/smb/database/list/AssignToSubscription'),
    'app/dns-helper': () => import(/* webpackChunkName: "app/dns-helper" */'../app/dns-helper'),
    'app/dns-helper/dns-settings': () => import(/* webpackChunkName: "app/dns-helper/dns-settings" */'../app/dns-helper/dns-settings'),
    'app/dns-zone/soa-record-rname': () => import(/* webpackChunkName: "app/dns-zone/soa-record-rname" */'../app/dns-zone/soa-record-rname'),
    'app/email-address/aliases-dynamic': () => import(/* webpackChunkName: "app/email-address/aliases-dynamic" */'../app/email-address/aliases-dynamic'),
    'app/email-address/configure-client': () => import(/* webpackChunkName: "app/email-address/configure-client" */'../app/email-address/configure-client'),
    'app/email-address/form-ajax': () => import(/* webpackChunkName: "app/email-address/form-ajax" */'../app/email-address/form-ajax'),
    'app/email-address/postbox': () => import(/* webpackChunkName: "app/email-address/postbox" */'../app/email-address/postbox'),
    'app/email-address/responder-attachments-dynamic': () => import(
        /* webpackChunkName: "app/email-address/responder-attachments-dynamic" */
        '../app/email-address/responder-attachments-dynamic'
    ),
    'app/file-manager/explorer': () => import(/* webpackChunkName: "app/file-manager/explorer" */'../app/file-manager/explorer'),
    'app/file-manager/upload-progress-dialog': () => import(/* webpackChunkName: "app/file-manager/upload-progress-dialog" */'../app/file-manager/upload-progress-dialog'),
    'app/file-manager/file-name-popup-form': () => import(/* webpackChunkName: "app/file-manager/file-name-popup-form" */'../app/file-manager/file-name-popup-form'),
    'app/hosting-settings/conflicts-checker': () => import(/* webpackChunkName: "app/hosting-settings/conflicts-checker" */'../app/hosting-settings/conflicts-checker'),
    'app/log-browser/browser': () => import(/* webpackChunkName: "app/log-browser/browser" */'../app/log-browser/browser'),
    'app/log-browser/files': () => import(/* webpackChunkName: "app/log-browser/files" */'../app/log-browser/files'),
    'app/login/recovery-password': () => import(/* webpackChunkName: "app/login/recovery-password" */'../app/login/recovery-password'),
    'app/mail-settings-popup-manager': () => import(/* webpackChunkName: "app/mail-settings-popup-manager" */'../app/mail-settings-popup-manager'),
    'app/newsletter-subscription': () => import(/* webpackChunkName: "app/newsletter-subscription" */'../app/newsletter-subscription'),
    'app/progress-dialog': () => import(/* webpackChunkName: "app/progress-dialog" */'../app/progress-dialog'),
    'app/profile/announcement-settings': () => import(/* webpackChunkName: "app/profile/announcement-settings" */'../app/profile/announcement-settings'),
    'app/pum': () => import(/* webpackChunkName: "app/pum" */'../app/pum'),
    'app/server/actionlog': () => import(/* webpackChunkName: "app/server/actionlog" */'../app/server/actionlog'),
    'app/server/key-info': () => import(/* webpackChunkName: "app/server/key-info" */'../app/server/key-info'),
    'app/server/key-revert': () => import(/* webpackChunkName: "app/server/key-revert" */'../app/server/key-revert'),
    'app/server/key-update': () => import(/* webpackChunkName: "app/server/key-update" */'../app/server/key-update'),
    'app/server/key-upload': () => import(/* webpackChunkName: "app/server/key-upload" */'../app/server/key-upload'),
    'app/server/logo': () => import(/* webpackChunkName: "app/server/logo" */'../app/server/logo'),
    'app/server/mail': () => import(/* webpackChunkName: "app/server/mail" */'../app/server/mail'),
    'app/server/notifications': () => import(/* webpackChunkName: "app/server/notifications" */'../app/server/notifications'),
    'app/show-output-popup': () => import(/* webpackChunkName: "app/show-output-popup" */'../app/show-output-popup'),
    'app/task-progress-bar': () => import(/* webpackChunkName: "app/task-progress-bar" */'../app/task-progress-bar'),
    'app/user/login-string': () => import(/* webpackChunkName: "app/user/login-string" */'../app/user/login-string'),
    'app/change-customers-owner': () => import(/* webpackChunkName: "app/change-customers-owner" */'../app/change-customers-owner'),
    'app/convert-customers-to-resellers': () => import(/* webpackChunkName: "app/convert-customers-to-resellers" */'../app/convert-customers-to-resellers'),
    'app/convert-resellers-to-customers': () => import(/* webpackChunkName: "app/convert-resellers-to-customers" */'../app/convert-resellers-to-customers'),
    'app/service-sidebar': () => import(/* webpackChunkName: "app/service-sidebar" */'./sidebars/ServiceSidebar'),
    'app/notifications/panel-notifications-settings': () => import(
        /* webpackChunkName: "app/notifications/panel-notifications-settings" */
        '../app/notifications/panel-notifications-settings'
    ),
    'app/ssl-certificate/list': () => import(/* webpackChunkName: "app/ssl-certificate/list" */'../app/ssl-certificate/list'),
    'app/app/progress': () => import(/* webpackChunkName: "app/app/progress" */'../app/app/progress'),
    'app/app/installed': () => import(/* webpackChunkName: "app/app/installed" */'../app/app/installed'),
    'app/app/apscatalog': () => import(/* webpackChunkName: "app/app/apscatalog" */'../app/app/apscatalog'),
};

export default async (path, callback) => {
    if (!entries[path]) {
        throw new Error(`Unknown required module ${path}.`);
    }

    try {
        const { default: module } = await entries[path]();
        callback?.(module);
        return module;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return new Promise(() => {});
    }
};
