// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable camelcase */

import { createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Translate, Form, FormFieldText, FormFieldPassword, FormFieldSelect, SelectOption, Action, StatusMessage, Link } from '@plesk/ui-library';
import { withLoginLayout } from './LoginLayout';
import RouteLink from './Link';

import LOGIN_PAGE_QUERY from 'queries/LoginPage.graphql';

const Login = ({ values, availableLocales, params }, { locale }) => {
    const { data: { config, serverInfo: { isFirstLogin, cloudType } = {} } = {} } = useQuery(LOGIN_PAGE_QUERY);

    const handleLocaleChange = value => {
        document.querySelector('input[name="locale_id"]').value = value;
        document.getElementById('form-login').submit();
    };

    const handleSubmit = values => {
        if (values.login_name === '' || values.passwd === '') {
            // eslint-disable-next-line no-alert
            alert(locale.lmsg('enterLoginAndPasswd'));
            return;
        }
        document.getElementById('form-login').submit();
    };

    return (
        <Fragment>
            {(isFirstLogin && cloudType === 'DO') ? (
                <StatusMessage intent="info" className="login-page__info">
                    <Translate
                        content="digitalOceanMsg"
                        params={
                            { link: (
                                <Link
                                    // eslint-disable-next-line max-len
                                    href="https://docs.plesk.com/en-US/obsidian/deployment-guide/plesk-installation-and-upgrade-on-public-cloud-services/installing-plesk-on-digitalocean.79699/#accessing-plesk-for-the-first-time"
                                    target="_blank"
                                >
                                    <Translate content="readGuide" />
                                </Link>
                            ) }
                        }
                    />
                </StatusMessage>
            ) : (isFirstLogin && cloudType === 'AMI_LIGHTSAIL') ? (
                <StatusMessage intent="info" className="login-page__info">
                    <Translate
                        content="amazonLightsailMsg"
                        params={
                            { link: (
                                <Link
                                    // eslint-disable-next-line max-len
                                    href="https://docs.plesk.com/en-US/obsidian/deployment-guide/plesk-installation-and-upgrade-on-public-cloud-services/installing-plesk-on-amazon-lightsail.79028/#accessing-plesk-for-the-first-time"
                                    target="_blank"
                                >
                                    <Translate content="readGuide" />
                                </Link>
                            ) }
                        }
                    />
                </StatusMessage>
            ) : null }
            <Form
                id="form-login"
                method="post"
                applyButton={false}
                cancelButton={false}
                submitButton={{
                    name: 'send',
                    children: <Translate content="loginButtonLabel" />,
                    type: 'submit',
                    fill: true,
                    className: 'login-page__login-button',
                }}
                onSubmit={handleSubmit}
                vertical
                values={values}
                className="login-page__form"
            >
                <div id="loginSection">
                    <FormFieldText
                        name="login_name"
                        label={<Translate content="loginLabel" />}
                        autoFocus
                        size="fill"
                    />
                    <FormFieldPassword
                        name="passwd"
                        label={<Translate content="passwdLabel" />}
                        hideGenerateButton
                        hidePasswordMeter
                        size="fill"
                    />
                    <FormFieldSelect
                        name="locale_id"
                        label={<Translate content="localeLabel" />}
                        size="fill"
                        onChange={handleLocaleChange}
                    >
                        <SelectOption value="default" label="default"><Translate content="defaultLocale" /></SelectOption>
                        {availableLocales.map(({ code, name, dir }) => (
                            <SelectOption key={code} value={code} dir={dir}>{name}</SelectOption>
                        ))}
                    </FormFieldSelect>
                </div>

                {document.getElementById('forgery_protection_token') ? (
                    <input
                        type="hidden"
                        name="forgery_protection_token"
                        value={document.getElementById('forgery_protection_token').content}
                    />
                ) : null}

                {Object.keys(params).map(name => (
                    <input
                        key={name}
                        name={name}
                        value={params[name]}
                        type="hidden"
                    />
                ))}
            </Form>
            <div className="login-page__links">
                {config?.login?.howToLoginArticle ? (
                    <Action
                        icon="question-mark-circle"
                        href={config.login.howToLoginArticle}
                        target="_blank"
                    >
                        <Translate content="whatUsernamePassword" />
                    </Action>
                ) : null}
                <Action
                    icon="lock-closed"
                    href={'/get_password.php'.concat(values.locale_id ? '?locale='.concat(values.locale_id) : '')}
                >
                    <Translate content="forgotPasswordLabel" />
                </Action>
                {config?.gdpr?.cookieSettings?.loginFormEntrypointEnabled && (
                    <Action
                        component={RouteLink}
                        icon="globe"
                        to="?modals[cookie-policy-preferences]=true"
                    >
                        <Translate content="cookies" />
                    </Action>
                )}
            </div>
        </Fragment>
    );
};

Login.propTypes = {
    values: PropTypes.shape({
        login_name: PropTypes.string,
        passwd: PropTypes.string,
        locale_id: PropTypes.string,
    }),
    availableLocales: PropTypes.array.isRequired,
    params: PropTypes.object,
};

Login.defaultProps = {
    params: {},
    values: {
        locale_id: 'default',
    },
};

Login.contextTypes = {
    locale: PropTypes.object,
};

export default withLoginLayout(Login);
