// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Dropdown, Menu, MenuItem, MenuDivider, Button } from '@plesk/ui-library';
import { Locale, prepareUrl } from 'jsw';
import Link from '../Link';

import HELP_MENU_QUERY from 'queries/HelpMenu.graphql';

/* global getHelpUrl, SetContext, SetHelpPrefix, GetContext, GetHelpModule */

const Translate = Locale.getTranslate('components.shortcuts');

const HelpMenu = ({
    helpUrl,
    customHelpUrl,
    helpContext,
    helpPrefix,
}) => {
    const { data: { viewer: user, config, mode } = {} } = useQuery(HELP_MENU_QUERY);

    if (helpContext) {
        // eslint-disable-next-line new-cap
        SetContext(helpContext);
    }
    if (helpPrefix) {
        // eslint-disable-next-line new-cap
        SetHelpPrefix(helpPrefix);
    }

    if (helpUrl && !mode?.isClassicPanel) {
        /* eslint-disable new-cap */
        if (GetContext()) {
            helpUrl += `/plesk-context/${GetContext()}`;
        }
        if (GetHelpModule()) {
            helpUrl += `/plesk-module/${GetHelpModule()}`;
        }
        /* eslint-enable new-cap */
    }

    const tutorialsUrl = config?.help?.tutorials?.url;
    const helpCenterUrl = config?.help?.helpCenterUrl;

    return (
        <Dropdown
            menu={(
                <Menu
                    data-type="page-header-help-menu"
                >
                    {user?.type === 'ADMIN' && mode?.isLicenseDeveloper ? (
                        <MenuItem
                            component="a"
                            icon="console"
                            href={config?.extensions?.developerGuideUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Translate content="extensionDeveloperGuide" />
                        </MenuItem>
                    ) : null}
                    <MenuItem
                        data-type="link-read-manual"
                        component="a"
                        icon="volume"
                        href={customHelpUrl || helpUrl || getHelpUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Translate content="readManual" />
                    </MenuItem>
                    {tutorialsUrl && !mode?.isLicenseLocked ? (
                        <MenuItem
                            component={Link}
                            icon="start-circle"
                            to={prepareUrl(tutorialsUrl)}
                            target={tutorialsUrl[0] === '/' ? null : '_blank'}
                            rel={tutorialsUrl[0] === '/' ? null : 'noopener noreferrer'}
                        >
                            <Translate content="tutorials" />
                        </MenuItem>
                    ) : null}
                    {user?.type === 'ADMIN' ? (
                        <Fragment>
                            <MenuItem
                                component="a"
                                icon="lightbulb"
                                href="https://support.plesk.com/hc/categories/201413825-Technical-Questions"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Translate content="knowledgeBaseArticles" />
                            </MenuItem>
                            {helpCenterUrl ? (
                                <MenuItem
                                    component="a"
                                    icon="lifebuoy"
                                    href={helpCenterUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Translate content="helpCenter" />
                                </MenuItem>
                            ) : null}
                            <MenuDivider />
                            <MenuItem
                                component="a"
                                icon="facebook"
                                href="https://www.facebook.com/groups/plesk"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Translate content="pleskFacebookCommunity" />
                            </MenuItem>
                            {config?.feedback?.forumUrl ? (
                                <MenuItem
                                    component="a"
                                    icon="chat"
                                    href={config?.feedback?.forumUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Translate content="pleskForum" />
                                </MenuItem>
                            ) : null}
                            <MenuDivider />
                            {config?.feedback?.enabled ? (
                                <MenuItem
                                    component="a"
                                    icon="bug"
                                    href="/admin/mpc/feedback"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Translate content="linkToFeedback" />
                                </MenuItem>
                            ) : null}
                            <MenuItem
                                component="a"
                                icon="megaphone"
                                href="https://docs.plesk.com/release-notes/obsidian/change-log/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Translate content="releaseNotes" />
                            </MenuItem>
                            {!mode?.isLicenseLocked ? (
                                <MenuItem
                                    component={Link}
                                    icon="info-circle"
                                    to="/cp/about"
                                >
                                    <Translate content="aboutPlesk" />
                                </MenuItem>
                            ) : null}
                        </Fragment>
                    ) : null}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            <Button id="help-menu" ghost icon="question-mark-circle" />
        </Dropdown>
    );
};

HelpMenu.propTypes = {
    helpUrl: PropTypes.string,
    customHelpUrl: PropTypes.string,
    helpContext: PropTypes.string,
    helpPrefix: PropTypes.string,
};

HelpMenu.defaultProps = {
    helpUrl: undefined,
    customHelpUrl: undefined,
    helpContext: undefined,
    helpPrefix: undefined,
};

export default HelpMenu;
